@font-face {
  font-family: Sahitya;
  src: url("./fonts/Sahitya-Bold.ttf");
}
@font-face {
  font-family: MontSerrat;
  src: url("./fonts/Montserrat-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: MontSerrat;
  src: url("./fonts/Montserrat-Regular.ttf");
}

body {
  background-color: #232441;
}


/* HEADER */


header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Sahitya";
  text-transform: uppercase;
  font-size: 1.5rem;
}
.nav-mobile {
  display: none;
}
.nav-titre {
  color: #efc031;
  font-size: 1.5rem;
  margin-left: 3rem;
}
.nav-desktop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin-right: 3rem;
}
.nav-item {
  margin: 0 2rem;
  text-decoration: none;
  color: white;
}
.nav-item.active {
  color: #efc031;
}


/* HOME */


.section-home {
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.home-pres,
.home-infos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.home-pres img {
  width: 8rem;
}
.home-pres h1 {
  font-family: "Sahitya";
  color: #efc031;
  text-transform: uppercase;
  font-size: 2rem;
  text-align: center;
}
.home-pres h2 {
  color: white;
  font-family: "MontSerrat";
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5rem;
  padding: 0 1rem;
}
.home-infos p{
  color: white;
  font-family: "MontSerrat";
  font-size: 1.3rem;
  text-align: center;
}
.home-infos strong{
  color: #efc031
}
.home-btn{
  font-family: "MontSerrat";
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  font-size: 1.3rem;
  color: #232441;
  background-color: white;
  padding: 1.2rem 4rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 1rem;
  cursor: pointer;
}
.home-barre{
  width: 0.2rem;
  height: 50%;
  background-color: white;
}


/* LOGIN AND REGISTRATION */


.section-login, .section-register,
.section-renvoi{
  height: 90vh;
}
.section-login form, .section-login, 
.section-register, .section-register form,
.section-renvoi{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.section-login,
.section-register{
  position: relative;
}
.section-login form,
.section-register form{
  width: 20%;
}
.section-login h2,
.section-register h2,
.section-membres h2,
.section-renvoi h2{
  font-family: "Sahitya";
  color: #efc031;
  text-transform: uppercase;
}
.section-login h3,
.section-register h3{
  font-family: "MontSerrat";
  text-align: center;
  padding: 0 2rem;
  color: white;
}
.section-register h3 a,
.section-register strong{
  color: #efc031;
}
.inputlabel{
  position: relative;
  width: 100%;
  height: 5rem;
}
.labelform{
  position: absolute;
  left: 1rem;
  top: 1.2rem;
  font-size: 1rem;
  padding: 0 .2rem;
  font-family: "MontSerrat";
  color: white;
  cursor: text;
  transition: top 200ms ease-in,
    left 200ms ease-in,
    font-size 200ms ease-in;
    background-color: #232441;
}
.inputform{
  color: white;
  background-color: #232441;
  font-size: 1rem;
  font-family: "MontSerrat";
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border: solid 2px white;
  width: 100%;
  height: 3.5rem;
  padding: 0 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  transition: all 200ms ease-in;
}
.inputform:hover,
.inputform:focus{
  outline: #efc031;
  border-color: #efc031;
}
.inputform:focus ~ .labelform,
.inputform:not(:placeholder-shown).inputform:not(:focus) ~ .labelform
{
  top:-0.5rem;
  font-size: .9rem;
  left: .8rem;
  color: #efc031;
}
.btn-register{
  position: absolute;
  bottom: 0;
}
.section-renvoi p{
  text-align: center;
}

/* LISTE DE FILMS */


.section-liste{
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
}
.div-film{
  width: 80%;
  height: 20rem;
  margin: 2rem auto;
  background-color: white;
  border-radius: 0 0 20px 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.film-poster{
  max-height: 60%;
  overflow: hidden;
}
.film-poster img{
  max-width: 100%;
}
.film-infos{
  margin: .5rem 2rem .5rem 2rem;
  max-height: 40%;
  min-height: 40%;
}
.film-infos h3{
  font-family: "Sahitya";
  text-transform: uppercase;
  text-decoration: underline;
  text-align: center;
}
.film-infos>div{
  font-family: "MontSerrat";
  display: flex;
  justify-content: space-between;
}
.film-infos>div>div,
.div-modal-score{
  font-family: "MontSerrat";
  display: flex;
  color: #efc031;
  flex-direction: row;
  align-items: center;
}
.film-infos svg,
.div-modal-score svg{
  width: 1.2rem;
  margin-right: .3rem;
}


/* MODAL FILM */


.modal-film{
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 121;
  background: rgba(35, 36, 65, 0.95);
}
.return-svg{
  top: 1rem;
  left: 1rem;
  width: 3rem;
  z-index: 130;
  position: absolute;
  cursor: pointer;
  fill: white;
  transition: all 200ms ease-in-out;
}
.return-svg:hover{
  fill: #efc031;
}
.div-modal-film{
  z-index: 120;
  height: 95vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  width: 100%;
}
.div-modal-infos,
.div-modal-score{
  font-family: "MontSerrat";
  font-size: 1.5rem;
}
.div-modal-score{
  justify-content: center;
}
.div-modal-film img{
  width: 30%;
  margin: 0 3rem;
}
.div-modal-film h3{
  font-family: "Sahitya";
  text-transform: uppercase;
  text-align: center;
  font-size: 5rem;
  margin: 0;
}
.form-modal{
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #232441;
  border-radius: 3px;
  justify-self: center;
  margin-left: auto;
  margin-right: auto;
}
.form-modal button{
  background-color: #efc031;
  border: 2px solid #efc031;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 3px;
  color: white;
  font-size: 1.1rem;
  text-align: center;
  z-index: 2;
  cursor: pointer;
}
.form-modal input{
  height: 2rem;
  border-radius: 3px;
  border: 2px solid #ffffff;
  border-right: none;
  background-color: #232441;
  z-index: 1;
  width: 4rem;
  margin-right: -0.2rem;
  color: white;
  transition: all 200ms ease-in;
}
.form-modal input:focus{
  border-color: #efc031;
  outline: #efc031;
}


/* MEMBRES */


.section-membres{
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.div-membres{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
}
.membre-fiche{
  display: flex;
  flex-direction: column;
  margin: 2rem;
  align-items: center;
  cursor: pointer;
}
.membre-fiche img{
  border: 3px solid white;
  width: 60%;
  border-radius: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.membre-fiche h3{
  color: #efc031;
  font-family: "MontSerrat";
  text-transform: uppercase;
}
.div-temps,
.modal-film-infos{
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  margin: 1rem 0;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.modal-film-infos br{
  display: none;
}

.modal-membres-infos h3{
  color: #efc031;
}
.modal-film h4{
  font-family: "MontSerrat";
  text-align: center;
  font-size: 1.5rem;
}
.modal-film-infos img{
  width: 4rem;
  height: 4rem;
}
.membres-avatar{
  border: 3px solid white;
  border-radius: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.div-temps h4{
  font-family: "MontSerrat";
  width: max-content;
  margin-right: .5rem;
}
.div-membres strong{
  color: #efc031;
}


/* PROFIL */


.section-profil{
  text-align: center;
  height: 100vh;
}
.section-profil strong{
  color: #efc031;
}
.section-profil > div{
  min-height: 100vh;
}
.modal-profil{
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
}
.section-profil > div{
  z-index: 80;
}
.edit-svg{
  width: 3rem;
  z-index: 90;
  cursor: pointer;
  fill: white;
  transition: all 200ms ease-in-out;
}
.edit-svg:hover{
  fill: #efc031;
}
.edit-mobile{
  position: absolute;
  display: none;  
  top: 1.5rem;
  right: 1rem;
}
.edit-desktop{
  display: unset;
}
.section-renvoi p{
  color: white;
  font-family: "MontSerrat";
}
.form-avatar{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
}
.form-avatar img{
  position: relative;
  width: 60%;
  aspect-ratio: 1 / 1;
}
.form-avatar input{
  display: none;
}
.form-avatar label{
  position: absolute;
  bottom: 20%;
  width: 4rem;
  height: 4rem;
  right: 10%;
  cursor: pointer;
}
.form-avatar label::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3rem;
  height: 3rem;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2rem' height='2rem' viewBox='0 0 20 20'%3E%3Cpath d='M17 12v5H3v-5H1v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5z' fill='%23ffffff'/%3E%3Cpath d='M15 7l-5-6-5 6h4v8h2V7h4z' fill='%23ffffff'/%3E%3C/svg%3E%0A") center / contain no-repeat;
  background-color: #efc031;
  border-radius: 100%;
  background-size: 2rem;
  border: 3px solid #efc031;
}
.form-modal-profil{
  margin-top: 1rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.form-modal-profil>div{
  width: 100%;
  margin-top: 1rem;
}
.form-modal-profil>label{
  font-family: "MontSerrat";
  color: white;
}
.form-modal-profil>div>input{
  width: 100%;
}
.form-avatar button{
  position: absolute;
  bottom: 0;
  font-family: "MontSerrat";
  z-index: 5;
  padding: .4rem .8rem;
  border-radius: 5px;
  font-size: 1.5rem;
  color: white;
  background-color: #efc031;
  border: none;
  cursor: pointer;
}


/* LOADING */


.section-loading{
  position: absolute;
  height: 95vh;
  width: 100%;
  z-index: 100;
  background: rgba(35, 36, 65, 0.95);
}
.section-loading>div{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 142px;
  height: 40px;
  margin: -20px 0 0 -71px;
}
.loader{
  position: absolute;
  width: 16px;
  height: 16px;
  top: 12px;
  left: 15px;
  background: #efc031;
  border-radius: 50%;
  transform: translateX(0);
  filter: blur(.2px);
  animation: loader 2.8s infinite
}
.loaders{
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  animation: loaders 2.8s infinite;
}
.loaders span{
  display: block;
  float: left;
  width: 16px;
  height: 16px;
  margin-left: 16px;
  filter: blur(.2px);
  background: #efc031;
  border-radius: 50%;
}

@keyframes loader{
  50%{
    transform: translateX(96px)
  }
}
@keyframes loaders{
  50%{
    transform: translateX(-31px)
  }
}


/* ADD FILM */

.poster-form{
  position: relative;
}
.poster-notdefined{
  display: block;
  background-color: lightgrey;
  width: 1500px;
  height: 800px;
}
.form-poster{
  position: absolute;
  z-index: 45;
  width: 100%;
  top: 5%;
  display: flex;
  flex-direction: column;
}


@media screen and (max-width: 810px) {
  /* HEADER */
  header {
    justify-content: center;
  }
  .nav-desktop,
  .nav-titre {
    display: none;
  }
  .nav-mobile {
    z-index: 120;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    list-style: none;
    background: #ffffff;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
    padding: 0;
  }
  .nav-item {
    margin: 0.5rem 0;
  }
  .nav-item svg {
    fill: #232441;
  }
  .nav-item.active svg {
    fill: #efc031;
  }


  /* HOME */


  .section-home {
    flex-direction: column;
    min-height: 100vh;
  }
  .home-pres,
  .home-infos {
    height: 50%;
    max-height: 50%;
    width: 100%;
  }
  .home-barre {
    background-color: white;
    width: 80%;
    height: 0.2rem;
  }


  /* LOGIN ET REGISTRATION */


  .section-login form,
  .section-register form{
    width: 80%;
  }


  /* LISTE FILMS */
  .section-liste{
    display: block;
  }


  /* MODAL FILM */


  .div-modal-film{
    flex-direction: column;
    justify-content: unset;    
    position: absolute;
    top: 5%;
  }
  .div-modal-film>div{
    margin: 0 3rem;
  }
  .div-modal-film img{
    width: 50%;
    margin: 0;
  }
  .div-modal-film h3{
    margin: 1rem;
    font-size: 2rem;
  }
  .div-modal-infos,
  .div-modal-score{
    align-items: center;
    font-size: 1rem;
  }


  /* MEMBRES */


  .div-membres{
    display: block;
    margin-bottom: 2rem;
    overflow: hidden;
    width: 100%;
  }
  .modal-film h4{
    font-size: 1rem;
  }
  .modal-film-infos{
    flex-direction: column;
    width: 100%;
  }
  .modal-film-infos br{
    display: unset;
  }
  .div-temps{
    flex-direction: column;
    margin: 0;
  }
  .div-temps h4{
    margin: .2rem;
  }


  /* PROFIL */


  .edit-svg{
    top: 1rem;
  }
  .edit-mobile{
    display: unset;
  }
  .edit-desktop{
    display: none;
  }
}
